import React from 'react';
import { Container } from 'reactstrap';
import 'bootstrap/dist/css/bootstrap.css';
import Head from '../components/head/head';
import NavBar, { NavBarSpacer } from '../components/nav-bar/navBar';
import Footer from '../components/footer/footer';
import BodyCopy from '../components/body-copy/bodyCopy';

const Home = (): JSX.Element => {
  return (
    <Container fluid>
      <Head />
      <NavBar />
      <NavBarSpacer />
      <BodyCopy>
        <article>
          <h1>Cookies Notice</h1>
          <div>Last Updated: August 1st 2020</div>
          <h2>Introduction</h2>
          <div>
            <p>
              This Cookies Notice (and the relevant websites referenced) tell
              you everything that you need to know about how HOP uses cookies. A
              cookie is a small file of letters and numbers that are stored on
              your browser, the hard drive of your computer or mobile device, if
              you agree. We need to tell you about this because our website,
              applications and other digital platforms (referred to as “sites”)
              use cookies to distinguish you from other users. This helps us to
              provide you with an enjoyable and personalised experience when you
              browse our sites. It also helps us to improve our sites, so that
              you can enjoy it even more when you return later on.
            </p>
          </div>
          <div>
            <h2>1. Are cookies safe?</h2>
            <p>
              Cookies are safe for your devices. They only store information
              used by your browser. They can’t access any other content on your
              device, nor do they create malware or viruses.
            </p>
          </div>
          <div>
            <h2>2. Are there different types of cookies?</h2>
            <p>
              Cookies fall into two main categories: session cookies or
              persistent cookies and are explained below. Session cookies: These
              last for the duration of your visit to a site. They delete
              themselves when you close your broswer. Persistent cookies: These
              stay on your dervice when you close your browser. These cookies
              are further broken down into four main types of cookies as
              explained below. Strictly necessary cookies: These are cookies
              that are required for the operation of our sites. They include,
              for example, cookies that enable you to log into a secure area of
              our sites. e Functionality cookies: These are used to recognise
              you when you return to our sites. This enables us to personalise
              our content for you and remember your preferences. Analytical or
              performance cookies: These allow us to recognise and count the
              number of visitors and to see how visitors move around our sites
              when they are using it. This helps us to improve the way our sites
              works, for example, by ensuring that our users, like you, are
              finding what they are looking for easily. Marketing cookies: These
              cookies record your visit to our sites, the pages you have visited
              and the websites you have followed. We will use this information
              to make our sites and the advertising displayed on it more
              relevant to your interests. We may also share this information
              with third parties for this purpose. Some of our cookies collect
              data for more than one use. We will only use strictly necessary
              cookies for their essential purposes unless you have given us
              consent to any other uses that they have.
            </p>
          </div>
          <div>
            <h2>3. What about third party cookies?</h2>
            <p>
              As we use third parties when providing you with our sites, we need
              to let you know that there are third parties that may also use
              cookies on your browser or hard drive and we have no control over
              them when they do this. These third parties may include, for
              example, advertising networks and providers of external services
              like web traffic analysis services. These third party cookies are
              likely to be analytical cookies or performance cookies or targeted
              cookies. If you do not feel comfortable with this, you can block
              cookies by activating the setting on your browser that allows you
              to refuse the setting of all or some cookies. However, if you do
              use your browser setting to block all cookies (including strictly
              necessary and therefore essential cookies), you may not be able to
              access all or parts of our sites and enjoy all the functionalities
              available.
            </p>
          </div>
          <div>
            <h2>
              4. Would a cookie on my browser be considered my personal data?
            </h2>
            <p>
              As cookies allow us to identify you as an individual, we have your
              personal data in our systems. We believe in the importance of data
              privacy and treat your personal data with care. If you would like
              to find out more about this, please take a look at our Privacy
              Notice.
            </p>
          </div>
          <div>
            <h2>5. What if I share my device with other people?</h2>
            <p>
              If more than one person uses your device, the choices set by
              others will apply to you as well unless you specifically change
              them when you are using your device. For example, if you share a
              computer with your family, you may see adverts based on websites
              they have visited as well as websites you have visited.
            </p>
          </div>
          <div>
            <h2>
              6. What happens if I change or turn off one or more types of
              cookies in my browser?
            </h2>
            <p>
              You can visit the “options” or “preferences” menu or your browser
              to change your settings. Other useful websites that you may wish
              to check out www.aboutcookies.org and www.allaboutcookies.org. We
              are not responsible for the information on any of the website
              above, but we’re happy to recommend it. You can also choose to
              entirely turn off or delete cookies in your browser. If you do
              this, it may affect websites that use similar cookies to us. This
              is because the cookies choices you set in your browser replace any
              cookies choices you set ona single website. For instance, if you
              run an advertising blocker on your browser, you won’t see adverts
              that are tailored to you, even if you have turned on marketing
              cookies for our sites.
            </p>
          </div>
          <div>
            <h3>Want to know more information or need help?</h3>
            <p>
              By using our sites, we’re assuming you’re happy with our use of
              cookies. If you have any questions about it, please do get in
              touch with us at privacy@myhop.io
            </p>
          </div>
        </article>
      </BodyCopy>
      <Footer />
    </Container>
  );
};

export default Home;
